<template>
  <div class="_wrap">
    <div class="_banner">
      <!-- <img src="@/assets/image/home-img/com_home_banner.png" alt="八路军驻洛办事处纪念馆-官方网站"> -->
      <img
        :src="$IMG_URL + $store.state.webData.top_image.sea_list_image"
        alt="八路军驻洛办事处纪念馆-官方网站"
      />
    </div>

    <div class="_content" id="sjhd">
      <div class="_auto">
        <div class="_sjhd">
          <div class="_left">
            <h4>
              <span>社教活动</span>
              <div
                class="_more"
                @click="
                  $router.push({ path: '/moreEdu', query: { tit: '社教活动' } })
                "
              >
                更多/more
              </div>
            </h4>

            <div class="swiper-container swiper-container0" ref="floor1Swiper">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in activtyList"
                  :key="index"
                  @click="goDetail(item, '社教活动')"
                  style="cursor: pointer"
                >
                  <div class="_img">
                    <img
                      :src="$IMG_URL + item.cover"
                      alt="八路军驻洛办事处纪念馆-官方网站"
                    />
                  </div>
                  <p class="_h4">{{ item.title }}</p>

                  <p class="_p">
                    {{ item.introduction }}
                  </p>
                </div>
              </div>
              <!-- 如果需要分页器 -->
              <div class="swiper-pagination"></div>

              <!-- 如果需要导航按钮 -->
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>

          <div class="_right">
            <h4>
              <span>实景党课</span>
              <div
                class="_more"
                @click="
                  $router.push({ path: '/moreEdu', query: { tit: '实景党课' } })
                "
              >
                更多/more
              </div>
            </h4>

            <div class="_r_c">
              <div
                class="_li"
                v-for="(item, index) in sjdkList"
                :key="index"
                @click="goDetail(item, '实景党课')"
              >
                <img
                  v-if="index == 0"
                  :src="$IMG_URL + item.cover"
                  alt="八路军驻洛办事处纪念馆-官方网站"
                  class="_r_img"
                />

                <p class="_tit">{{ item.title }}</p>
                <p class="_p">{{ item.introduction }}</p>
              </div>
              <!-- <div class="_li">
                <p class="_tit">洛八办：我党在豫西地区的“红色枢纽”（重 走洛阳抗战纪念地和遗址）</p>
              </div>

              <div class="_li">
                <p class="_tit">洛八办：我党在豫西地区的“红色枢纽”（重 走洛阳抗战纪念地和遗址）</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="_zyz" id="zyz">
      <h4>
        <span>志愿者</span>
        <div
          class="_more"
          @click="
            $router.push({
              path: '/moreEdu',
              query: { tit: '志愿者', subtit: jumpName },
            })
          "
        >
          更多/more
        </div>
      </h4>

      <div class="_cont">
        <div class="_tabs">
          <div
            :class="{ _li: true, _active: current == index }"
            v-for="(item, index) in tabsList"
            :key="index"
            @click="tabChange(item, index)"
          >
            {{ item.name }}
          </div>
        </div>

        <div class="_cont_1">
          <div v-show="current == 0">
            <div class="swiper-container swiper-container1">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in zyzList"
                  :key="index"
                >
                  <div class="_img">
                    <img
                      :src="$IMG_URL + item.image"
                      alt="八路军驻洛办事处纪念馆-官方网站"
                    />
                  </div>
                  <p class="_h4">{{ item.title }}</p>
                </div>
              </div>

              <!-- 如果需要导航按钮 -->
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>

          <div v-show="current == 1">
            <div class="_zm_cont">
              <div class="zm_nr" v-if="vr.length > 0" v-html="vr"></div>

              <div class="_nodata" v-else>暂无数据...</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mainFooter ref="mainFooterRef"></mainFooter>
  </div>
</template>

<script>
import { getSEA, partyClass, volunteer, hits } from "@/api/getData";
import Swiper from "swiper";

export default {
  data() {
    return {
      wbTitle: "八路军驻洛办事处纪念馆-社教",
      keywords: "八路军驻洛办事处纪念馆,洛八办",
      desc: "八路军驻洛办事处纪念馆",
      current: 0,
      tabsList: [
        {
          name: "志愿者风采",
        },
        {
          name: "志愿者招募",
        },
      ],
      jumpName: "志愿者风采",
      activtyList: [],
      sjdkList: [],
      zyzList: [],
      vr: "",
      seen: false,
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: this.wbTitle,
      // meta: [
      //   { name: "keywords", content: this.keywords },
      //   { name: "description", content: this.desc },
      // ]
    };
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      console.log(to);
      let title = to.query.tit;
      this.title = title;
      for (let i in this.obj) {
        console.log(i);
        if (this.title == i) {
          this.$scrollToSection(this.obj[i]);
          return;
        }
      }
    },
  },
  async mounted() {
    this.menuSeen();
    await this.getSEA();
    await this.volunteer();
    await this.partyClass();
    this.initSwiper();
    this.initSwiper1();
    this.jumpId();
  },
  created() {},
  methods: {
    jumpId() {
      const title = this.$route.query.tit;
      console.log(title);
      const obj = {
        社教活动: "sjhd",
        实景党课: "sjhd",
        志愿者: "zyz",
      };
      this.obj = obj;

      for (let i in obj) {
        console.log(i);
        if (title == i) {
          this.$scrollToSection(obj[i]);
          return;
        } else {
          window.scrollTo({
            top: 0,
            behavior: "smooth", // 平滑滚动
          });
        }
      }
    },
    menuSeen() {
      // 屏幕尺寸
      let screenWidth = document.body.clientWidth;
      // console.log(screenWidth)
      if (screenWidth > 800) {
        this.seen = true;
      } else {
        this.seen = false;
      }
    },
    initSwiper() {
      new Swiper(".swiper-container0", {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          dynamicMainBullets: 2,
          clickable: true,
        },
      });
    },

    initSwiper1() {
      let spaceBetween;
      if (this.seen) {
        spaceBetween = 36;
      } else {
        spaceBetween = 20;
      }
      new Swiper(".swiper-container1", {
        slidesPerView: "auto",
        spaceBetween: spaceBetween,

        freeMode: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },

    goDetail(item, name) {
      if (name == "社教活动") {
        if (item.link != null && item.link != "") {
          const params = {
            type: 2,
            id: item.id,
          };
          hits(params);
          window.open(item.link);
        } else {
          let url = location.origin;

          window.open(`${url}/educationDetail?id=${item.id}&breadName=${name}`);
        }
      } else {
        if (item.link != null && item.link != "") {
          const params = {
            type: 4,
            id: item.id,
          };
          hits(params);
          window.open(item.link);
        } else {
          let url = location.origin;
          window.open(`${url}/educationDetail?id=${item.id}&breadName=${name}`);
        }
      }
    },

    tabChange(item, index) {
      this.current = index;
      this.jumpName = item.name;
    },

    // 社教活动
    async getSEA() {
      const params = {
        page: 1,
        page_num: 3,
      };
      await getSEA(params).then((res) => {
        console.log(res);
        this.activtyList = res.data.data;
      });
    },
    // 实景党课
    async partyClass() {
      const params = {
        page: 1,
        page_num: 3,
      };
      await partyClass(params).then((res) => {
        console.log(res);
        this.sjdkList = res.data.data;
      });
    },
    // 志愿者

    async volunteer() {
      await volunteer().then((res) => {
        console.log(res);
        this.zyzList = res.data.vd; //志愿者风采
        this.vr = res.data.vr; //志愿者招募
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

@mixin ba_cont {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #831313;
  transform: rotate(45deg);
}

._nodata {
  font-size: 20px;
  text-align: center;
}

._wrap {
  width: 100%;
  overflow: hidden;
  background-color: #fdfaf6;
}

._banner {
  width: 100%;

  height: 700px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

._more:hover {
  color: $hover-color;
}

._more {
  padding: 7px 8px;
  border: 1px solid #d8d8d8;
  color: #333;
  cursor: pointer;
}

._auto {
  width: 75%;
  margin: 0 auto;
}

._content {
  margin-top: 33px;

  ._sjhd {
    display: flex;
    justify-content: space-between;

    ._left {
      width: 63.8%;

      h4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 38px;

        span {
          font-size: 36px;
          color: #333;
          display: flex;
          align-items: center;
        }

        span::before {
          @include ba_cont();
          margin-right: 20px;
        }
      }

      ._img {
        width: 100%;
        height: 523px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      ._h4 {
        font-size: 24px;
        margin: 30px 0 21px;
      }

      ._p {
        font-size: 18px;
        color: #666;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        /* 设置最大显示行数 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        width: 100%;
      }
    }

    ._right {
      width: 33%;

      h4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 38px;

        span {
          font-size: 36px;
          color: #333;
          display: flex;
          align-items: center;
        }

        span::before {
          @include ba_cont();
          margin-right: 20px;
        }
      }

      ._r_c {
        ._r_img {
          width: 100%;
          height: 246px;
          margin-bottom: 28px;
          object-fit: cover;
        }

        ._li:hover ._tit {
          color: $hover-color;
        }

        ._li {
          border-bottom: 1px solid #dbdbdb;
          margin-top: 25px;
          padding-bottom: 35px;
          cursor: pointer;

          ._tit {
            font-size: 24px;
            color: #333;
            overflow: hidden;

            white-space: nowrap;
            text-overflow: ellipsis;
          }

          ._p {
            font-size: 21px;
            color: #666;
            margin-top: 27px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            /* 设置最大显示行数 */
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            width: 100%;
          }
        }
      }
    }
  }
}

._zyz {
  width: 100%;
  height: 700px;
  background-image: url("../../assets/image/education-img/shejiao_bg_zhiyuanzhe.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 60px;
  overflow: hidden;
  margin-bottom: 80px;

  h4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 38px;
    width: 75%;
    margin: 0 auto;
    margin-top: 50px;

    span {
      font-size: 36px;
      color: white;
      display: flex;
      align-items: center;
    }

    span::before {
      @include ba_cont();
      margin-right: 20px;
      background: #fff;
    }
  }

  ._more:hover {
    color: $hover-color;
  }

  ._more {
    color: white;
  }

  ._cont {
    ._tabs {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 60px 0 42px;

      ._li {
        padding: 0 53px;
        color: #8296bd;
        font-size: 24px;
        position: relative;
        cursor: pointer;
      }

      ._active {
        color: white;

        position: relative;
      }

      ._active::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        border: 1px solid #fff;
        transform: rotate(45deg);
        position: absolute;
        top: 14px;
        left: 30px;
      }

      ._active::after {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        border: 1px solid #fff;
        transform: rotate(45deg);
        position: absolute;
        top: 14px;
        right: 30px;
      }

      ._li:nth-child(1) {
        border-right: 1px solid #7a88a4;
      }
    }

    ._cont_1 {
      ._img {
        width: 456px;
        height: 288px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      ._h4 {
        font-size: 20px;
        color: white;
        margin-top: 22px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      ._zm_cont {
        width: 75%;
        margin: 0 auto;
        background: rgba(255, 255, 255, 0.1);
        height: 400px;

        .zm_nr {
          padding: 40px 0 47px 42px;
          // color: white;
        }
      }
    }
  }
}

.swiper-container {
  width: 100%;
  height: 780px;
  // overflow: hidden;
}

.swiper-container {
  // --swiper-theme-color: #ff6600;
  --swiper-pagination-color: #b41e1e;
  /* 两种都可以 */
}

.swiper-container0 .swiper-slide:hover ._h4 {
  color: $hover-color;
}

.swiper-container0 .swiper-button-prev {
  background-image: url("../../assets/image/education-img/zhanlan_icon_left.png");
  width: 57px;
  height: 14px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.swiper-container0 .swiper-button-next {
  background-image: url("../../assets/image/education-img/zhanlan_icon_right.png");
  width: 57px;
  height: 14px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.swiper-container0 .swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: "";
}

.swiper-container0 .swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "";
}

.swiper-container0 .swiper-button-next {
  top: 100%;
}

.swiper-container0 .swiper-button-prev {
  top: 100%;
}

.swiper-container0 .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 30%;
}

.swiper-container0 .swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 30%;
}

.swiper-container1 .swiper-slide {
  width: 456px !important;
}

.swiper-container1 {
  width: 100%;
  height: 450px;
}

.swiper-container1 {
  --swiper-theme-color: white;

  /* 两种都可以 */
}

.swiper-container1 .swiper-button-next,
.swiper-button-prev {
  top: 92%;
}

@media (max-width: 1441px) {
  .swiper-container1 .swiper-button-next {
    transform: translateX(50px);
  }
}

.swiper-container1 .swiper-button-prev {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid white;
}

.swiper-container1 .swiper-button-next {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid white;
}

.swiper-container1 .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 46.8%;
}

.swiper-container1 .swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 46.8%;
}

.swiper-container1 .swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  font-size: 20px;
}

.swiper-container1 .swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  font-size: 20px;
}

.swiper-container1 .swiper-slide {
  // right:15%;
}

// .swiper-container1  .swiper-slide-next{
//     left:-8%;
// }

@include respondTo("phone") {
  ._banner {
    height: 300px;
  }
  ._auto {
    padding: 0 8px;
  }
  ._content {
    margin-top: 20px;
    ._sjhd {
      display: block;
      ._left {
        width: 100%;
        h4 {
          margin-bottom: 20px;
          span {
            font-size: 22px;
          }
        }
        .swiper-container {
          height: 450px;
          ._img {
            height: 246px;
          }
          ._h4 {
            font-size: 20px;
            margin: 10px 0;
          }
          ._p {
            font-size: 16px;
            -webkit-line-clamp: 3;
          }
        }
      }
      ._right {
        width: 100%;
        h4 {
          margin-bottom: 20px;
          span {
            font-size: 22px;
          }
        }
        ._r_c {
          ._li {
            margin-top: 20px;
            padding-bottom: 20px;
            ._r_img {
              margin-bottom: 20px;
            }
            ._tit {
              font-size: 18px;
            }
            ._p {
              font-size: 16px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  ._zyz {
    margin-top: 20px;
    margin-bottom: 40px;
    height: 550px;
    h4 {
      margin-top: 20px;
      width: 100%;
      padding: 0 8px;
      span {
        font-size: 22px;
      }
    }
    ._cont {
      ._tabs {
        margin: 20px 0;
        ._li {
          font-size: 18px;
          padding: 0 30px;
        }
        ._active::before {
          top: 10px;
          left: 15px;
        }
        ._active::after {
          top: 10px;
          right: 15px;
        }
      }
      ._cont_1 {
        .swiper-container1 {
          height: 380px;

          .swiper-slide {
            width: 90% !important;
          }
          .swiper-button-next {
            transform: translateX(0);
          }
          ._img {
            width: 100%;
            height: 246px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          ._h4 {
            font-size: 18px;
            margin-top: 10px;
          }
        }

        ._zm_cont{
          overflow-y: scroll;
          width: 95%;
        }
      }
    }


  }
}

::v-deep .swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  @include respondTo("phone") {
    bottom: 40px;
  }
}
::v-deep .swiper-container0 .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  @include respondTo("phone") {
    left: 10%;
    top: auto;
    bottom: 40px;
  }
}

.swiper-container0 .swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  @include respondTo("phone") {
    right: 10%;
    top: auto;
    bottom: 40px;
  }
}

::v-deep .swiper-container1 .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  @include respondTo("phone") {
    left: 30%;
  }
}
::v-deep .swiper-container1 .swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  @include respondTo("phone") {
    right: 30%;
  }
}
</style>